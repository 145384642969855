import React from 'react';
import { getHumanPayoutData } from '../../../../../api/getHumanPayoutData';
import { PayoutRegister } from '../../../../../models/payoutregister.model';
import { useParams } from 'react-router-dom';
import { getDepositRecordById } from '../../../../../api/getHumanDCFunctions';
import { ExtendedDepositRegister } from './PurchaseCoinsPanel';
import RemovePayoutModal from '../../../modals/payout/RemovePayoutModal';

export default function PayoutRegisterPage() {
    const { id } = useParams();
    const [payoutRegisterData, setPayoutRegisterData] = React.useState<PayoutRegister[]>([]);
    const [myDeposit, setMyDeposit] = React.useState<ExtendedDepositRegister>();
    const [selectedPayoutRegisterData, setSelectedPayoutRegisterData] = React.useState<PayoutRegister>();
    const [removeModal, setRemoveModal] = React.useState<boolean>(false);
    const [stateChange, setStateChange] = React.useState<boolean>(false);
    
    const openRemoveModal = (payoutRegister: PayoutRegister) => {
        setSelectedPayoutRegisterData(payoutRegister);
        setRemoveModal(true);
    }

    const closeModal = () => {
        setRemoveModal(false);
    }

    React.useEffect(() => {
        if(!id) return;

        getDepositRecordById(parseInt(id, 10))
        .then((response) => {
            setMyDeposit(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [id])
    
    React.useEffect(() => {
        if(!id) return;

        getHumanPayoutData(parseInt(id, 10))
        .then((response) => {
            setPayoutRegisterData(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [id, stateChange]);

    const handleStateChange = () => {
        setStateChange(!stateChange);
    }

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"
    const validValue = "#E8D788";
    const defaultValue = "#D3D3D3";

    return (
        <>
            <RemovePayoutModal title={'Kifizetés törlése'} showModal={removeModal} closeModal={closeModal} row={selectedPayoutRegisterData} handleStateChange={handleStateChange}/>
        
            <div className="min-h-screen flex flex-col p-4">
            <h1 className='text-xl text-center mt-5 mb-5'><span className='font-semibold'>{myDeposit?.depositRegister.user.name}</span> kifizetései: ({payoutRegisterData.length}db)</h1>
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 text-white hover:opacity-80 cursor-pointer">ID</th>
                            <th className="border p-2 text-white hover:opacity-80 cursor-pointer">Dátum</th>
                            <th className="border p-2 text-white bg-gray-700 hover:opacity-80 cursor-pointer">Összeg</th>
                            <th className="border p-2 text-white bg-gray-700 hover:opacity-80 cursor-pointer">Kifizetés típusa</th>
                            <th className="border p-2 text-white bg-gray-700 hover:opacity-80 cursor-pointer sr-only">Törlés</th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {payoutRegisterData.map((record, index) => (
                            <tr key={record.id} className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{record.id}</td>
                                <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                                <td className={dataRowStyling} style={{ color: record.amount === 0 ? defaultValue : validValue}}>{record.amount} EUR</td>
                                <td className={dataRowStyling} style={{ color: record.capital ? defaultValue : validValue}}>{record.capital ? 'Tőke visszafizetés' : 'Nyereség'}</td>
                            
                                <td className={dataRowStyling}>
                                    <p onClick={() => {
                                        openRemoveModal(record)
                                    }} className="text-error hover:text-dark-blue text-md font-bold text-center p-2 cursor-pointer">Visszavonás</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>    
            </div>
        </div>
    </>
    );
}
