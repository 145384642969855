import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CloseLeverage, OpenLeverage } from "../../../../models/leveragetrading.model";
import { Group } from "../../../../models/group.model";
import { responseHero } from "../../../../utils/axiosInterceptor";

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    row: OpenLeverage | undefined;
    handleStateChange: () => void;
};

type ModifiedCloseLeverage = Pick<CloseLeverage, "group_id" | "percentage" | "assetType" | "pl">

export const CloseLeverageTradingModal = ({ group, title, showModal, closeModal, row, handleStateChange }: ModalProps) => {

    /*
        export interface CloseLeverage {
        id: number;
        open_id: number; // Kapcsolat a nyitott pozícióval
        group_id: number;
        assetType: number;

        percentage: number; // Zárás mértéke százalékban (0-100)
        pl: string; // Profit/loss az adott záráskor
        date: Date; // Zárás dátuma

    */
    const [recordData, setRecordData] = useState<ModifiedCloseLeverage>({
            group_id: -1,
            assetType: -1,
            percentage: 100,
            pl: ""
    });

    React.useEffect(() => {
        if(!row) return;

        setRecordData({
            group_id: row.group_id,
            assetType: row.assetType,
            percentage: 100,
            pl: row.pl,
        })

    }, [row])

    const handleChange = (field: keyof ModifiedCloseLeverage, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const inputValue = e.target.value;
  
      setRecordData(prevData => {
        const updatedRecordData = {
            ...prevData,
            [field]: parseInt(inputValue, 10),
        };
        return updatedRecordData;
      });
    };

    const closeRecord = async () => {
        if(!group) return;
        await responseHero.post(`${process.env.REACT_APP_API_URL}/leverageclose/${group.group_id}`, { record: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen létrehoztad a zárást!`);
        }).catch(error => {
            closeModal()
            toast.error(`Sikertelen rögzítés!`);
        })
    }

    return (
        <>
            {showModal && (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-4xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                    <h3 className="text-xl text-brown font-semibold">
                                        <span className="text-dark-blue">HUMAN D.C: Tőkeáttét - {title}</span>
                                    </h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <div className="flex p-2 rounded shadow-md mb-5 items-center justify-center">
                                        <p className="text-xl">Biztosan lezárod ezt a pozíciót?</p>
                                    </div>  
                                    <div className="w-1/3 pr-2 items-center justify-center">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="base">
                                            <span className="text-success">Hány százalékos zárást hajtsunk végre?</span>
                                        </label>
                                        <input
                                            value={recordData.percentage}
                                            onChange={(e) => handleChange('percentage', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="percentage"
                                            type="number"
                                            placeholder="Százalék"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={closeModal}
                                    >
                                        Mégse
                                    </button>
                                    <button
                                        className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={closeRecord}
                                    >
                                        Zárás
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
                </>
            )}
        </>
    );
};
