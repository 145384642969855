import { PayoutRegister } from "../models/payoutregister.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getHumanPayoutData = (id: number): Promise<PayoutRegister[]> => {
    return new Promise<PayoutRegister[]>((resolve, reject) => {
        if(!id) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/payout/${id}`).then((response) => {
            resolve(response.data as PayoutRegister[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};