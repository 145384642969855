import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Enum for tab values
export enum TabValues {
  TAB_CRYPTO = 'TAB_CRYPTO',
  TAB_GOLD = 'TAB_GOLD',
  TAB_INV = 'TAB_INV',
  TAB_TRADING_HELP = 'TAB_TRADING_HELP',
  TAB_PORTFOLIO = 'TAB_PORTFOLIO',
  TAB_PAYOUT = 'TAB_PAYOUT',
  TAB_INVESTED_BOUNDARIES = 'TAB_INVESTED_BOUNDARIES',
  DASHBOARD = 'DASHBOARD',
}

// Interface for slice state
interface ITabs {
  selectedTab: TabValues;
}

// Initial state
const initialState: ITabs = {
  selectedTab: TabValues.TAB_INV,
};

// Redux slice
const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    SET_SELECTED_TAB: (state, action: PayloadAction<TabValues>) => {
      state.selectedTab = action.payload;
    },
  },
});

// Exports
export const { SET_SELECTED_TAB } = tabsSlice.actions;
export default tabsSlice.reducer;

// Selector to get selected tab state
export const selectHpmTabState = (state: { tabs: ITabs }) => state.tabs.selectedTab;
