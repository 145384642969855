import React from "react";
import { useSelector } from "react-redux";
import { OpenLeverage } from "../../../../models/leveragetrading.model";
import { getLeverageData } from "../../../../api/getLeverageData";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";
import { CloseLeverageTradingModal } from "../../modals/leverage/CloseLeverageTradingModal";
import { getCoinExchangeRate } from "../../../../api/getCoinExchangeRate";
import { Assets } from "../../../../enums/assets";
import { CoinPrices } from "../../../../pages/admin/LeveragedTrading";
import { ModifyLeverageTradingModal } from "../../modals/leverage/ModifyLeverageTradingModal";

type FormContainerProps = {
  handleStateChange: () => void;
  coinPrices: CoinPrices;
}

enum ModalType {
    None = "NONE",
    CloseModal = "CLOSE_MODAL",
    UpdateModal = "UPDATE_MODAL"
}

type LeverageRecord = {
  bitcoin:  OpenLeverage;
  ethereum: OpenLeverage;
}

export default function FormContainer({ handleStateChange, coinPrices } : FormContainerProps ) {
  const [activeModal, setActiveModal] = React.useState<ModalType>(ModalType.None);
  const [leverageRecord, setLeverageRecord] = React.useState<LeverageRecord>();
  const [selectedRecord, setSelectedRecord] = React.useState<OpenLeverage>();

  const menu = useSelector(selectMenuStates);


  const openModal = React.useCallback((modalType: ModalType, record: OpenLeverage) => {
      setSelectedRecord(record);
      setActiveModal(modalType);
  }, []);

  const closeModal = React.useCallback(() => {
      setActiveModal(ModalType.None);
  }, []);

  const fetchLeverageData = React.useCallback(async () => {
      try {
          const response01 = await getLeverageData(menu?.daisyMenu, Assets.BTC);
          const response02 = await getLeverageData(menu?.daisyMenu, Assets.ETH);

          setLeverageRecord({
              bitcoin: response01,
              ethereum: response02,
          });
      } catch (error) {
          console.error("Failed to fetch leverage data:", error);
      }
  }, [menu?.daisyMenu, handleStateChange]);

  React.useEffect(() => {
      fetchLeverageData();
  }, [fetchLeverageData]);

  const calculatePL = React.useCallback(
      (assetType: number, exchange_rate: number, open: number): string => {
          if (!coinPrices) return "0";

          const price = assetType === Assets.BTC ? coinPrices.btc : coinPrices.eth;
          const openInAsset = open / price;
          return ((price - exchange_rate) * openInAsset).toFixed(2);
      },
      [coinPrices]
  );

  const updatedRecords = React.useMemo(() => {
      if (!coinPrices || !leverageRecord) return null;

      return {
          bitcoin: {
              ...leverageRecord.bitcoin,
              pl: calculatePL(Assets.BTC, leverageRecord.bitcoin.exchange_rate, leverageRecord.bitcoin.open),
          },
          ethereum: {
              ...leverageRecord.ethereum,
              pl: calculatePL(Assets.ETH, leverageRecord.ethereum.exchange_rate, leverageRecord.ethereum.open),
          },
      };
  }, [leverageRecord, coinPrices, calculatePL]);

  const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer";

  const renderTable = (assetName: string, record: OpenLeverage, assetType: number) => (
            <div className="mb-5">
                <h2 className="text-xl text-center font-semibold mb-2 text-gray-800">{assetName}</h2>
                {record.base > 0 ?
                <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2">Alaptőke</th>
                            <th className="border p-2">Tőkeáttét</th>
                            <th className="border p-2">Tőkeáttétes pozíció</th>
                            <th className="border p-2">Vételi árfolyam</th>
                            <th className="border p-2">P/L</th>
                            <th className="border p-2">Módosítás</th>
                            <th className="border p-2">Zárás</th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        <tr key={record.id} className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                            <td className={dataRowStyling}>{record.base}$</td>
                            <td className={dataRowStyling}>{record.multiplier}X</td>
                            <td className={dataRowStyling}>{record.open}$</td>
                            <td className={dataRowStyling}>{record.exchange_rate}$</td>
                            <td className={`${dataRowStyling}
                            ${parseFloat(record.pl) > 0 ? 'text-emerald-500' : 'text-error'}`}>{record.pl}$</td>
                            <td className={dataRowStyling}>
                                <p
                                    onClick={() => openModal(ModalType.UpdateModal, record)}
                                    className="text-cream hover:text-emerald-200 text-md font-bold text-center p-2 cursor-pointer"
                                >
                                    Módosítás
                                </p>
                            </td>
                            <td className={dataRowStyling}>
                                <p
                                    onClick={() => openModal(ModalType.CloseModal, record)}
                                    className="text-cream hover:text-emerald-200 text-md font-bold text-center p-2 cursor-pointer"
                                >
                                    Zárás
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                :
                <p className="text-2xl text-center py-2 text-gray-600">Kérlek előbb nyisd meg a pozíciót!</p>
            }
            </div>
        )

  return (
      <>
          {activeModal === ModalType.UpdateModal && (
              <ModifyLeverageTradingModal
                  title={"Módosítás"}
                  group={menu?.daisyMenu}
                  showModal={activeModal === ModalType.UpdateModal}
                  closeModal={closeModal}
                  row={selectedRecord}
                  handleStateChange={handleStateChange}
                  coinPrices={coinPrices}
             />
          )}

          {activeModal === ModalType.CloseModal && (
              <CloseLeverageTradingModal
                  title={"Zárás"}
                  group={menu?.daisyMenu}
                  showModal={activeModal === ModalType.CloseModal}
                  closeModal={closeModal}
                  row={selectedRecord}
                  handleStateChange={handleStateChange}
              />
          )}
          <div className="rounded border flex justify-center items-center space-x-8 px-2 py-2 bg-white mb-5">
              <p className="text-lg">Bitcoin árfolyam: <span className="font-semibold">{coinPrices?.btc}$</span></p>
              <p className="text-lg">Ethereum árfolyam: <span className="font-semibold">{coinPrices?.eth}$</span></p>
          </div>
          <div className="w-full overflow-x-auto text-white h-full">
              <div style={{ maxHeight: 600, overflowY: "auto" }}>
                  {updatedRecords && (
                      <>
                          {renderTable("Bitcoin", updatedRecords.bitcoin, Assets.BTC)}
                          {renderTable("Ethereum", updatedRecords.ethereum, Assets.ETH)}
                      </>
                  )}
              </div>
          </div>
      </>
  );
}

