import React, { useState, useEffect } from "react";
import { InvestmentBoundaries } from "../../../../../types/InvestmentBoundaries";
import { getDepositBoundaries } from "../../../../../api/getDepositBoundaries";

type InvestmentBoundariesPanelProps = {
    group_id: number;
    assetType: number;
};

export default function InvestmentBoundariesPanel({
    group_id,
    assetType,
}: InvestmentBoundariesPanelProps) {
    const [records, setRecords] = useState<InvestmentBoundaries[]>([]);
    const [filteredDeposits, setFilteredDeposits] = useState<InvestmentBoundaries | null>(null);
    const [selectedBoundary, setSelectedBoundary] = useState<string>("");

    useEffect(() => {
        if (!assetType || !group_id) return;

        getDepositBoundaries(group_id, assetType)
            .then((response) => {
                setRecords(response);
                if (response.length > 0) {
                    setSelectedBoundary(
                        `${response[0].lowerBound}-${response[0].upperBound}`
                    ); // Default to the first boundary
                    setFilteredDeposits(response[0]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [group_id, assetType]);

    const handleBoundaryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = event.target.value;
        setSelectedBoundary(selected);

        const boundary = records.find(
            (record) =>
                `${record.lowerBound}-${record.upperBound}` === selected
        );

        setFilteredDeposits(boundary || null);
    };

    // Calculate averages
    const calculateAverages = () => {
        if (!filteredDeposits?.deposits.length) return { avgInvested: 0, avgAmount: 0, avgRate: 0 };
        const totalInvested = filteredDeposits.deposits.reduce((sum, { depositRegister }) => sum + (parseFloat(depositRegister.invested_amount_usdt.toString()) || 0), 0);
        const totalAmount = filteredDeposits.deposits.reduce((sum, { assetPurchase }) => sum + (parseFloat(assetPurchase.amount.toString()) || 0), 0);
        const totalRate = filteredDeposits.deposits.reduce((sum, { assetPurchase }) => sum + (parseFloat(assetPurchase.exchange_rate) || 0), 0);
        const count = filteredDeposits.deposits.length;

        return {
            avgInvested: (totalInvested / count).toFixed(2),
            avgAmount: (totalAmount / count).toFixed(2),
            avgRate: (totalRate / count).toFixed(2),
        };
    };

    const averages = calculateAverages();

    return (
        <>
            {records.length > 0 ?
                <div className="flex gap-4 p-4">
                    <div className="w-1/4">
                        <label
                            htmlFor="boundary-select"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Intervallum kiválasztása
                        </label>
                        <select
                            id="boundary-select"
                            value={selectedBoundary}
                            onChange={handleBoundaryChange}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg px-4 py-2"
                        >
                            {records.map((record) => (
                                <option
                                    key={`${record.lowerBound}-${record.upperBound}`}
                                    value={`${record.lowerBound}-${record.upperBound}`}
                                    className="text-lg"
                                >
                                    {`${record.lowerBound?.toFixed(2)}$ - ${record.upperBound?.toFixed(2)}$ (${record?.deposits.length || 0})`}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="w-3/4">
                        <table className="min-w-full divide-y divide-gray-200 border border-gray-300 shadow-md rounded-lg">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Befektetés tulajdonosa
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Befektetés dátuma
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Befizetés összege összesen (USD)
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Az eszközbe fektetve (USD)
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Ezen az árfolyamon fektett be
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredDeposits?.deposits.map(({ depositRegister, assetPurchase }) => (
                                    <tr key={depositRegister.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {depositRegister.user?.name || "N/A"}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {depositRegister.date?.toString().split('T')[0] || "N/A"}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {depositRegister.invested_amount_usdt || "N/A"}$
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {assetPurchase.amount}$
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {assetPurchase.exchange_rate}$
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot className="bg-gray-50">
                                <tr>
                                    <td colSpan={2} className="px-6 py-4 font-bold text-gray-700 text-center">
                                        Átlag:
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900 font-semibold">
                                        {averages.avgInvested}$
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900 font-semibold">
                                        {averages.avgAmount}$
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900 font-semibold">
                                        {averages.avgRate}$
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                :
                <p className="mt-2 text-center text-xl font-semibold">Ehhez az eszközhöz nem tartoznak befizetések a csoportban!</p>
            }
        </>
    );
}
