import { Group } from "../models/group.model";
import { OpenLeverage } from "../models/leveragetrading.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getLeverageData = (group: Group | undefined, assetType: number): Promise<OpenLeverage> => {
    return new Promise<OpenLeverage>((resolve, reject) => {
        if(!group) return;
        responseHero.get(`${process.env.REACT_APP_API_URL}/leverage/${group?.group_id}/${assetType}`).then((response) => {
            resolve(response.data as OpenLeverage);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};