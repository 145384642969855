import React from "react";
import { DaisyDeposits } from "../../../models/daisydeposits.model";
import { getDaisyDepositRecords } from "../../../api/getDaisyDepositRecords";
import AddNewDepositModal from "./modals/AddNewDepositModal";
import RemoveDepositModal from "./modals/RemoveDepositModal";
import { System } from "../../../models/system.model";
import { sys } from "typescript";

type DepositRegisterProps = {
    system: System | undefined;
}

export default function DepositRegister ({ system } : DepositRegisterProps) {
    const [records, setRecords] = React.useState<DaisyDeposits[]>([])
    const [originalRecords, setOriginalRecords] = React.useState<DaisyDeposits[]>([])
    const [totalAmount, setTotalAmount] = React.useState<number>(0);

    const [incoming, setIncoming]  = React.useState<boolean>(true); // incoming page = true / outcoming = false

    const [searchText, setSearchText] = React.useState<string>("");
    const [showNewRecordModal, setShowNewRecordModal] = React.useState<boolean>(false); // new record modal state
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)
    const [showRemoveRecordModal, setShowRemoveRecordModal] = React.useState<boolean>(false); // remove record modal state
    const [selectedRecord, setSelectedRecord] = React.useState<DaisyDeposits>();

    React.useEffect(() => {
        if(!system) return;

        getDaisyDepositRecords(incoming, system?.id)
        .then((response) => {
            setRecords(response)
            setOriginalRecords(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [recodsStateChange, incoming, system])

    React.useEffect(() => {
        const filteredNames = filterNames(originalRecords, searchText, "name");
        
        filteredNames.map((filteredName) => filteredName.deposit)
        setTotalAmount(filteredNames.reduce((sum, filteredName) => sum + filteredName.deposit, 0));
        setRecords(filteredNames);
      }, [searchText, originalRecords]);

    const openModal = () => {
        setShowNewRecordModal(true)
    };

    const openRemoveRecordModal = () => {
        setShowRemoveRecordModal(true)
    };

    const closeModal = () => {
        setShowNewRecordModal(false)
        setShowRemoveRecordModal(false)
    };

    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }

    const filterNames = (users: DaisyDeposits[], searchText: string, searchOption: string) => {
        const searchValue = searchText.toLowerCase();
        return users.filter((user) => {
          if (searchOption === 'name') {
            return user.user.name.toLowerCase().includes(searchValue);
          }
          return true;
        });
      };

    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"

    return (
        <>
        <AddNewDepositModal system={system} title="Új adatsor" showModal={showNewRecordModal} closeModal={closeModal} handleStateChange={handleStateChange} incoming={incoming}/>
        <RemoveDepositModal title="Adatsor törlése" record={selectedRecord} showModal={showRemoveRecordModal} closeModal={closeModal} handleStateChange={handleStateChange}/>

        <div className="m-10">
            <div className="w-full overflow-x-auto text-white">
                <div className="text-center flex">
                    <button
                        className={`flex-1 text-md text-center p-2 ${
                        incoming ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                        } hover:bg-black font-semibold border-l border-r border-white`}
                        onClick={() => setIncoming(true)}
                    >
                        {system?.name} - Befizetések kezelése
                    </button>
                    {/*}
                    <button
                        className={`flex-1 text-md text-center p-2 ${
                        !incoming ? 'bg-black text-cream font-semibold text-2xl' : 'bg-gray-700 text-gray-300'
                        } hover:bg-black font-semibold border-l border-r border-white`}
                        onClick={() => setIncoming(false)}
                    >
                        {system?.name} - Kifizetések kezelése
                    </button>
                    */}
                </div>
            </div>

            <div className="flex flex-row m-3 items-center justify-center text-center mt-5">
                <button className="mt-2 text-md text-center px-2 py-2 bg-success shadow-lg rounded mb-2 hover:opacity-80" onClick={() => openModal()}>Új 
                {incoming ? ' befizetés ' : ' kifizetés '} hozzáadása</button>
                <label className="ml-3 text-dark-blue text-md">Kereső: </label>
                <input onChange={(e) => setSearchText(e.target.value)} value={searchText} type="text" className="ml-2 px-2 py-2 rounded-md text-dark-blue focus:none" placeholder="Ügyfél neve"></input>
            </div>
            <p className="text-dark-blue text-lg text-center mt-1 mb-2">Jelenleg {incoming ? ' beutalt ' : ' kiutalt '} összeg, a szűrési feltételek alapján: <span className="text-dark-brown font-semibold">{totalAmount}$</span></p>
            <div className="flex justify-center border p-3 m-2 mx-auto">
            <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                <thead className="bg-brown text-white sticky top-0">
                <tr>
                    <th className="border p-2 hover:opacity-80 cursor-pointer">Sorszám</th>
                    <th className="border p-2 hover:opacity-80 cursor-pointer">Dátum</th>
                    <th className="border p-2 hover:opacity-80 cursor-pointer">Ügyfél neve</th>
                    <th className="border p-2 hover:opacity-80 cursor-pointer">Csoport neve</th>
                    <th className="border p-2 hover:opacity-80 cursor-pointer">{incoming ? 'Beutalt' : 'Kiutalt'} összeg</th>
                    <th className="border p-2 hover:opacity-80 cursor-pointer sr-only"></th>
                </tr>
                </thead>
                <tbody className="bg-dark-blue text-white">
                    {records.map((record, index) => (
                        <tr className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                            <td className={dataRowStyling}>{index + 1}.</td>
                            <td className={dataRowStyling}>{record.date.toString().split('T')[0]}</td>
                            <td className={dataRowStyling}>{record.user.name}</td>
                            <td className={dataRowStyling}>{record.group.group_name}</td>
                            <td className={dataRowStyling}>{record.deposit}$</td>

                            <td>
                                <p onClick={() => {
                                    setSelectedRecord(record)
                                    openRemoveRecordModal()
                                }} className="text-error hover:text-brown text-md font-bold text-center p-2 cursor-pointer">Törlés</p>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
            
            </div>
            </div>
            </>
    )
    
}