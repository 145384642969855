import { DaisyClosure } from "../models/daisyclosure.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getDaisyClosureData = (group_id: number): Promise<DaisyClosure[]> => {
    return new Promise<DaisyClosure[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/daisyclosure/${group_id}`).then((response) => {
            resolve(response.data as DaisyClosure[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};