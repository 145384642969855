import React from "react";
import NewsList from "../../components/news/NewsList";
import { getNewsLetterGroupData } from "../../api/getNewsLetterGroupData";
import { NewsLetterGroup } from "../../models/newslettergroup.model";
import { responseHero } from "../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Email } from "../../types/email";
import { getAllEmailsData } from "../../api/getAllEmailsData";

export default function NewsLetterManagerList () {

    const [newsLetterGroupList, setNewsLetterGroupList] = React.useState<NewsLetterGroup[]>([]);
    const [selectedGroupList, setSelectedGroupList] = React.useState<NewsLetterGroup>();
    const [mails, setMails] = React.useState<Email[]>([]);

    React.useEffect(() => {
        getNewsLetterGroupData()
        .then((response) => {
            setNewsLetterGroupList(response)
            const foundSelected = response.find((entity) => entity.selected);
            setSelectedGroupList(foundSelected);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);


    const handleOptionSelection = (option: string) => {
        const selectedGroup = newsLetterGroupList.find((entity) => entity.name === option);
        setSelectedGroupList(selectedGroup);
    }

    const saveNewsLetterList = async () => {
        if(!selectedGroupList) return;
        
        await responseHero.patch(`${process.env.REACT_APP_API_URL}/newslettergroup/${selectedGroupList.id}`).then((response) => {
            toast.success(`Sikeresen megváltoztattad a levelezőlistát!`);
        }).catch(error => {
            toast.error(`Sikertelen változtatás!`);
        })

        getAllEmailsData()
        .then((response) => {
            setMails(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }
    
    return (
        <div className="m-5 p-3">
            <div className="flex space-x-4">
                <div className="w-1/3">
                <div className="mt-2">
                    <select
                        value={selectedGroupList?.name || 'Nincs kiválasztva'}
                        onChange={(e) => handleOptionSelection(e.target.value)}
                        className="w-full bg-dark-blue text-white px-3 py-2 rounded-lg shadow-md"
                        >
                        <option
                            disabled={true}
                            value={"Nincs kiválasztva"}
                            className="px-2 py-1 text-center"
                        >
                            {"Nincs kiválasztva"}
                        </option>
                        {newsLetterGroupList.map((entity, index) => (
                            <option value={entity.name} className="px-2 py-1 text-center" key={index}>
                            {entity.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mt-2">
                    <p className="text-md">Jelenleg beállítva: {selectedGroupList?.name || "Nincs beállítva"}</p>
                </div>
                <div className="mt-2">
                    <button onClick={() => saveNewsLetterList()} className="px-2 py-2 text-white bg-success opacity-80 hover:opacity-100 rounded">
                        Beállítás
                    </button>
                </div>
                </div>
                <div className="w-2/3">
                {selectedGroupList && (
                    <div className="flex flex-col items-center">
                        <h1 className="font-semibold text-lg mb-4 text-center">
                        A {selectedGroupList?.name} listában szereplő címek: ({mails.length} db)
                        </h1>
                        <div className="overflow-x-auto w-full max-w-4xl">
                        <table className="min-w-full border border-gray-200 text-sm">
                            <thead className="bg-gray-100">
                            <tr>
                                <th className="text-left px-4 py-2 border-b border-gray-200 font-medium">Sorszám</th>
                                <th className="text-left px-4 py-2 border-b border-gray-200 font-medium">Név</th>
                                <th className="text-left px-4 py-2 border-b border-gray-200 font-medium">Email cím</th>
                            </tr>
                            </thead>
                            <tbody>
                            {mails.map((entity, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                <td className="px-4 py-2 border-b border-gray-200">{index + 1}</td>
                                <td className="px-4 py-2 border-b border-gray-200">{entity.name}</td>
                                <td className="px-4 py-2 border-b border-gray-200">{entity.email}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}