import { responseHero } from "../utils/axiosInterceptor";
import { Organization } from "../models/organization.model";

export const getMyOrgData = (
    user_id: number
): Promise<Organization[]> => {
    return new Promise<Organization[]>((resolve, reject) => {
        if(!user_id) return;
        
        const url = `${process.env.REACT_APP_API_URL}/myorg/${user_id}`;

        responseHero.get(url)
            .then((response) => {
                resolve(response.data as Organization[]);
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
    });
};