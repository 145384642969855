import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectNavStates } from "../../store/reducers/navSlice";
import { selectMenuStates } from "../../store/reducers/menuSlice";
import { getCurrentLiquidityValue } from "../../api/getCurrentLiquidityValue";
import { OpenLeverageTradingModal } from "../../components/humandc/modals/leverage/OpenLeverageTradingModal";
import FormContainer from "../../components/humandc/subcomponents/leveragetrading/FormContainer";
import { getCoinExchangeRate } from "../../api/getCoinExchangeRate";
import { ModifyLeverageTradingModal } from "../../components/humandc/modals/leverage/ModifyLeverageTradingModal";
import { OpenLeverage } from "../../models/leveragetrading.model";

export type CoinPrices = {
    btc: number;
    eth: number;
};

export default function LeveragedTrading() {
    const nav = useSelector(selectNavStates);
    const menu = useSelector(selectMenuStates);
    const [groupLiquidity, setGroupLiquidity] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [stateChange, setStateChange] = React.useState<boolean>(false);
    const [coinPrices, setCoinPrices] = React.useState<CoinPrices>({
        btc: 0,
        eth: 0,
    });
    
    const handleStateChange = () => setStateChange((prev) => !prev);

    useEffect(() => {
        if (!menu.daisyMenu) return;

        getCurrentLiquidityValue(menu?.daisyMenu.group_id)
        .then((response) => {
            setGroupLiquidity(response.amount);
        })
        .catch((error) => {
            console.error(error);
        });

    }, [menu?.daisyMenu]);

    const fetchCoinPrices = async () => {
        try {
            const [btc, eth] = await Promise.all([
                getCoinExchangeRate("90"),
                getCoinExchangeRate("80"),
            ]);
  
            setCoinPrices({
                btc: parseInt(btc, 10),
                eth: parseInt(eth, 10),
            });
        } catch (error) {
            console.error("Failed to fetch coin prices:", error);
        }
    };

    
    React.useEffect(() => {
        fetchCoinPrices();
    }, []);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="m-5 p-3">
            <p className="px-2 py-2 text-xl text-center">
                Jelenlegi likviditás:
                <span className="font-semibold">
                    {groupLiquidity?.toLocaleString('en-En').replace(',', ' ') || 0}$
                </span>
            </p>
            <div className="flex flex-row space-x-4 py-2 px-4 justify-center items-center">
                <button
                    className="bg-success text-white rounded hover:opacity-100 opacity-80 px-2 py-2"
                    onClick={openModal}
                >
                    Új pozíció nyitása
                </button>
            </div>

            {/* Modal component */}
            {isModalOpen && (
                <OpenLeverageTradingModal
                    title="Új pozíció"
                    group={menu?.daisyMenu}
                    showModal={isModalOpen}
                    closeModal={closeModal}
                    handleStateChange={handleStateChange}
                    coinPrices={coinPrices}
                />
            )}
            
            <div className="mt-3">
                <FormContainer handleStateChange={handleStateChange} coinPrices={coinPrices}/>
            </div>
            
        </div>
    );
}
