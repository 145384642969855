import { ModalProps } from "../../../types/modalProps";
import { getUserInvestments, UserInvestments } from "../../../api/getUserInvestments";
import React, { useEffect } from "react";
const UserInvestmentsModal = ({ title, user, showModal, closeModal } : Omit<ModalProps, "handleStateChange">) => {

  const [investments, setInvestment] = React.useState<UserInvestments>();

  useEffect(() => {
    if(!user) return;
    getUserInvestments(user?.user_id).then((response) => {
        setInvestment(response)
    }).catch((error) => {
        console.error(error);
    })
  }, [user]);


  return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <h3 className="text-3xl font=semibold">{title}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <p className="text-xl mb-2"><b>{user?.name}</b> befektetései: </p>
                            <p className="text-md">Human D.C és Human D.C Altcoin: 
                                <span className="font-semibold"> {investments?.hdc.toLocaleString('en-En').replace(',', ' ')} EUR</span></p>
                            <p className="text-md">D.A.I.SY: 
                                <span className="font-semibold"> {investments?.daisy.toLocaleString('en-En').replace(',', ' ')}$</span></p>
                            <p className="text-md">Bányagép projekt: 
                                <span className="font-semibold"> {investments?.mining.toLocaleString('en-En').replace(',', ' ')}$</span></p>
                            <p className="text-md">Blockchain Sports: <span className="font-semibold"> {investments?.bs.toLocaleString('en-En').replace(',', ' ')} EUR</span></p>
                            <p className="text-md">Aranyprogram: Még nem áll rendelkezésre adat</p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Bezár
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )}
    </>
  );
};

export default UserInvestmentsModal;