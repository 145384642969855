import { responseHero } from "../utils/axiosInterceptor";

export type UserInvestments = {
    hdc: number;
    daisy: number;
    mining: number;
    bs: number;
}
export const getUserInvestments = (user_id: number): Promise<UserInvestments> => {
    return new Promise<UserInvestments>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/userInvestments/${user_id}`).then((response) => {
            resolve(response.data as UserInvestments);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};