import { responseHero } from "../utils/axiosInterceptor";

export const getCoinExchangeRate = (code: string | undefined): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        if(!code) return;
        responseHero.get(`${process.env.REACT_APP_API_URL}/humandc/coinprice/${code}`).then((response) => {
            resolve(response.data as string);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};