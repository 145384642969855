import React from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getMyCryptos } from "../../../../../api/getMyCryptos";
import { getDepositRecords } from "../../../../../api/getHumanDCFunctions";
import { Group } from "../../../../../models/group.model";
import { selectUser } from "../../../../../store/reducers/userSlice";
import { PortfolioStates } from "../../../../../types/humandc";
import Decimal from "decimal.js";
import { DepositMetaData } from "../../../../../types/humandc/old";


export default function FormContainer () {

    const [portfolioStates, setPortfolioStates] = React.useState<DepositMetaData[]>([]); // portfolio states
    const menu = useSelector(selectMenuStates)
    const user = useSelector(selectUser);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [totalLiquidity, setTotalLiquidity] = React.useState<Decimal>();
    
    const getPortfolio = React.useCallback(async (group: Group) => {
        setLoading(true)
        try {
            const deposits = await getDepositRecords(group);
            if (deposits.length > 0) {
                const portfolio = await getMyCryptos(group.group_id, deposits.map(deposit => deposit.id));
                if (portfolio) {
                    console.log(JSON.stringify(portfolio.depositMetaData, null, 2))
                    const sortedPortfolio = portfolio.depositMetaData.sort((a, b) => b.rate - a.rate);
                    setPortfolioStates(sortedPortfolio);
                    setTotalLiquidity(portfolio.depositMetaData.reduce((acc, curr) => acc.plus(curr.liquidity), new Decimal(0)));
                }
            }
        }
        finally {
            setLoading(false)
        }
    }, []);
    

    const fetchPortfolioData = async () => {
        if(!menu?.daisyMenu) return;
        await getPortfolio(menu?.daisyMenu);
        //await updateGroupLiquidity(menu?.daisyMenu);
    }

    // styling
    const dataRowStyling = "text-center text-lg font-bold p-3 border-2 hover:opacity-80 cursor-pointer"

    return (
        <>
        <div className="flex items-center justify-center mt-2">
            <button disabled={loading} className="text-center text-xl px-2 py-2 bg-dark-brown text-white opacity-80 hover:opacity-100" onClick={fetchPortfolioData}>Adatok lekérdezése</button>
        </div>

        {loading ? <div className="m-5 animate-pulse">Kérlek várj...</div> : (
        <div className="w-full overflow-x-auto text-white h-full">
            <div>
                <p className="text-center font-semibold text-dark-blue py-2">Kalkulált likviditás: {totalLiquidity?.toString() || 0}$</p>
            </div>
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Befizetés dátuma</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Név</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Likviditás</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Százalékos megtérülés</th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {portfolioStates.map((entity, index) => (
                            <tr key={index} className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{entity.date.toString().split('T')[0]}</td>
                                <td className={dataRowStyling}>{entity.name}</td>
                                <td className={dataRowStyling}>{Math.round(parseFloat(entity.liquidity.toString())).toString()}$</td>
                                <td className={`${dataRowStyling} ${new Decimal(entity?.rate).isNegative() ? 'text-error' : 'text-success'}`}>{entity?.rate?.toString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        )}
        </>
    )
}