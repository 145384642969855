import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectMenuStates } from "../../../store/reducers/menuSlice";
import { useNavigate } from "react-router";
import { getMiningProduction } from "../../../api/getMiningMachines";
import { MiningMachine } from "../../../models/miningmachine.model";
import { MiningProduction } from "../../../models/miningproduction.model";
import { PlusIcon } from "../../../styles/assets/svgs/PlusIcon";
import RemoveProductionCostModal from "./modals/RemoveEnergyCostModal";
import ModifyMiningMachineModal from "./modals/ModifyMiningMachineModal";

type ProductionCostProps = {
    miningMachine: MiningMachine | undefined;
}

export default function MiningProductionList ({ miningMachine } : ProductionCostProps ) {

    const menu = useSelector(selectMenuStates)

    const navigate = useNavigate();
    
    const [records, setRecords] = React.useState<MiningProduction[]>([]);

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [showModifyModal, setShowModifyModal] = React.useState<boolean>(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = React.useState<MiningProduction>();

    const [recordStateChange, setRecordStateChange] = React.useState(false);
    
    const handleStateChange = () => setRecordStateChange(!recordStateChange);

    React.useEffect(() => {
        if(!miningMachine) return;
        getMiningProduction(miningMachine?.id)
        .then((response) => {
            setRecords(response);
        })
        .catch((error) => {
            console.error(error);
        });
        
    }, [recordStateChange, miningMachine])

    const closeModal = () => { 
        setShowRemoveModal(false) 
        setShowModifyModal(false); 
        setShowModal(false);
    }

    const openModal = () => {
        setShowModal(true);
    }

    const openRemoveModal = (record: MiningProduction) => {
        setShowRemoveModal(true);
        setSelectedRecord(record);
    }

    const openModifyModal = (record: MiningProduction) => {
        setShowModifyModal(true);
        setSelectedRecord(record);
    }

    return (
        <>  
            <RemoveProductionCostModal title={"Termelési adatsor törlése"} showModal={showRemoveModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord}/>
            <ModifyMiningMachineModal title={"Termelési adatsor módosítása"} showModal={showModifyModal} closeModal={closeModal} handleStateChange={handleStateChange} row={selectedRecord} miningMachine={miningMachine}/>
            
            {records.length === 0 ? <p className="text-center">Az <span className="text-brown">{miningMachine?.name}</span> termelései jelenleg még nincsenek rögzítve!</p> : 
            <Fragment>
                <h2 className="text-lg">A(z) <span className="font-semibold">{miningMachine?.name}</span> termelési adatai:</h2>
            
                <div className="flex justify-center border bg-dark-blue p-3 m-2 mx-auto">
                
                <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                
                    <thead className="bg-dark-blue text-cream">
                        <tr>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Dátum</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Termelt {miningMachine?.miningMachine?.coin}</th>
                            <th className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Részesedik</th>
                            <th className="py-3 px-6 text-xs text-center font-medium tracking-wider text-left uppercase text-cream" colSpan={2}>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y bg-dark-blue divide-gray-600">
                    
                        {records.map((record, index) => (
                            <tr key={index} className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.id}
                                </td>
                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.date.toString().split('T')[0]}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-cream">
                                    {parseFloat(record.amount)?.toFixed(4)}
                                </td>

                                <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
                                    {record.customDistribution ? 'Ügyfél és Human D.C' : 'Ügyfél'}
                                </td>

                                <td onClick={() => openModifyModal(record)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-cream hover:cursor-pointer hover:underline">Módosít</p>
                                </td>
                                <td onClick={() => openRemoveModal(record)}>
                                    <p className="py-4 px-6 text-sm font-medium whitespace-nowrap text-error hover:cursor-pointer hover:underline">Törlés</p>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                </Fragment>
            }
        </>
    )
}