import React, { useState, useEffect } from "react";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Group } from "../../../../models/group.model";
import { DepositRegister } from "../../../../models/depositregister";
import { PayoutRegister } from "../../../../models/payoutregister.model";

type ModifiedPayload = Partial<PayoutRegister>;

type ModalProps = {
  title: string;
  group: Group | undefined;
  showModal: boolean;
  closeModal: () => void;
  depositRegister: DepositRegister | undefined;
};

const AddNewPayoutModal = ({
  group,
  title,
  showModal,
  closeModal,
  depositRegister,
}: ModalProps) => {
  const [recordData, setRecordData] = useState<ModifiedPayload>({
    amount: 0,
    capital: true,
    date: new Date(),
    depositRegisterId: depositRegister?.id ?? 0,
  });

  // Update state if depositRegister changes
  useEffect(() => {
    if(!depositRegister) return;

    setRecordData((prevState) => ({
      ...prevState,
      amount: parseInt(depositRegister?.invested_amount_eur.toString(), 10) ?? 0,
      depositRegisterId: depositRegister?.id ?? 0,
    }));
  }, [depositRegister]);

  const handleChange = (key: keyof ModifiedPayload, value: any) => {
    setRecordData((prevData) => {
      let parsedValue: any = value;
  
      // Handle specific types
      if (key === "amount") {
        parsedValue = Number(value); // Convert to number (handles floats and integers)
        if (isNaN(parsedValue)) parsedValue = 0; // Fallback for invalid input
      } else if (key === "capital") {
        parsedValue = Boolean(value); // Convert to boolean
      } else if (key === "date") {
        parsedValue = value ? new Date(value) : null; // Convert to Date
      }
  
      return {
        ...prevData,
        [key]: parsedValue,
      };
    });
  };
  

  // Submit the record to the backend
  const createRecord = async () => {
    if (!group) return;

    try {
      console.log("payload: " + JSON.stringify(recordData, null, 2));

      await responseHero.post(`${process.env.REACT_APP_API_URL}/humandc/payout`, {
        record: recordData,
      });
      closeModal();
      toast.success(`Sikeresen hozzáadtál egy rekordot!`);
    } catch (error) {
      console.error(error);
      toast.error(`Sikertelen adatrögzítés!`);
    }
  };

  return (
    <>
      {showModal && (
        <>
          <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-white">
                {/* Header */}
                <div className="flex items-center justify-between p-5 border-b rounded-t">
                  <h3 className="text-xl text-brown font-semibold">
                    <span className="text-dark-blue">HUMAN D.C</span> - {title}
                  </h3>
                </div>

                <div className="flex items-center justify-between p-5 border-b rounded-t">
                  <p className="text-md text-dark-blue">
                    Kifizetés létrehozása{" "}
                    <span className="font-bold">{depositRegister?.user.name}</span>{" "}
                    részére.
                  </p>
                </div>

                {/* Form */}
                <div className="p-6">
                  <form className="flex flex-row gap-8 justify-center items-start">
                    {/* Amount Input */}
                    <div className="flex flex-col w-1/4">
                      <label className="block text-sm font-bold text-gray-700 mb-1 text-center">
                        Kifizetett összeg (EUR)
                      </label>
                      <input
                        type="number"
                        value={recordData?.amount}
                        onChange={(e) =>
                          handleChange("amount", e.target.value)
                        }
                        className="text-center border rounded w-full py-2 px-3 text-gray-700 focus:outline-none"
                        placeholder="EUR"
                      />
                    </div>

                    {/* Capital Checkbox */}
                    <div className="flex flex-col w-1/4">
                      <label className="block text-sm font-bold text-gray-700 mb-1 text-center">
                        Tőke
                      </label>
                      <div className="flex justify-center">
                        <input
                          type="checkbox"
                          checked={recordData.capital ?? false}
                          onChange={(e) =>
                            handleChange("capital", e.target.checked)
                          }
                          className="w-5 h-5 cursor-pointer"
                        />
                      </div>
                    </div>

                    {/* Date Input */}
                    <div className="flex flex-col w-1/4">
                      <label className="block text-sm font-bold text-gray-700 mb-1 text-center">
                        Dátum
                      </label>
                      <input
                        type="date"
                        value={recordData.date?.toISOString().split("T")[0] || ""}
                        onChange={(e) =>
                          handleChange("date", e.target.value)
                        }
                        className="text-center border rounded w-full py-2 px-3 text-gray-700 focus:outline-none"
                      />
                    </div>
                  </form>
                </div>

                {/* Footer */}
                <div className="flex items-center justify-end p-6 border-t">
                  <button
                    className="text-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-2"
                    type="button"
                    onClick={closeModal}
                  >
                    Mégse
                  </button>
                  <button
                    className="text-white bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg"
                    type="button"
                    onClick={createRecord}
                  >
                    Hozzáad
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Overlay */}
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
        </>
      )}
    </>
  );
};

export default AddNewPayoutModal;
