import { DaisySummarize, GroupSummarization } from "../models/types/DaisySummarize";
import { responseHero } from "../utils/axiosInterceptor";

export const getDaisySumInfoByUserGroup = (group_id: number): Promise<DaisySummarize> => {
    return new Promise<DaisySummarize>((resolve, reject) => {
        if(!group_id) return;
        responseHero.get(`${process.env.REACT_APP_API_URL}/daisypayout/${group_id}`).then((response) => {
            resolve(response.data as DaisySummarize);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export const getDaisySumInfoByTotalGroup = (): Promise<DaisySummarize> => {
    return new Promise<DaisySummarize>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/daisypayouts`).then((response) => {
            resolve(response.data as DaisySummarize);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};