import { Route, Routes } from 'react-router-dom'
import { Layout } from '../layout/Layout'
import Home from '../pages/Home'
import Login from '../pages/Login'
import { checkTokenValidity } from '../utils/checkTokenValidity'
import { Navigate } from 'react-router'

import NoPage from '../pages/NoPage'

import ContractsPage from '../pages/admin/ContractsPage'
import DaisyMainPage from '../pages/admin/DaisyMainPage'
import HPMMainPage from '../pages/admin/HPMMainPage'
import UserManager from '../pages/admin/UserManager'
import GroupManager from '../pages/admin/GroupManager'
import CompanyDetails from '../pages/admin/CompanyDetails'
import ContractHistory from '../components/contract/ContractHistory'
import OrgManager from '../pages/admin/OrgManager'
import MiningMachine from '../pages/admin/MiningMachine'
import DepositRegister from '../components/common/depositregister/DepositRegister'
import NewsManager from '../pages/admin/NewsManager'
import AltCoin from '../pages/admin/TemporaryPage'
import HumanGold from '../pages/admin/HumanGold'
import Vote from '../pages/admin/Vote'
import TopList from '../pages/admin/TopList'
import MyMiningMachine from '../components/miningmachine/MyMiningMachine'
import MiningMachineTypeManager from '../components/miningmachine/MiningMachineTypeManager'
import GroupConnectionsList from '../components/group/subcomponents/GroupConnectionsList'
import MiningBeneficiariesPanel from '../components/miningmachine/MiningBeneficiariesPanel'
import { Systems } from '../enums/systems'
import DepositRegisterPage from '../pages/admin/DepositRegisterPage'
import NewsLetterPanel from '../pages/admin/NewsLetter'
import TemporaryPage from '../pages/admin/TemporaryPage'
import PurchaseCoinsPanel from '../components/humandc/subcomponents/tables/deposit/PurchaseCoinsPanel'
import MinedCoinTraderPanel from '../components/humandc/extensions/minedcoinstrading/MinedCoinTraderPanel'
import LeveragedTrading from '../pages/admin/LeveragedTrading'
import PayoutRegisterPage from '../components/humandc/subcomponents/tables/deposit/PayoutRegisterPage'
import DaisyPayoutManager from '../pages/admin/DaisyPayoutManager'
import TotalDaisyPayoutManager from '../pages/admin/TotalDaisyPayoutManager'
import NewsLetterManagerList from '../pages/admin/NewsLetterManagerList'
import VoteLabelManager from '../pages/admin/VoteLabelManager'
export default function Router() {

  const isAuthenticated = checkTokenValidity(); // Function to check the validity of JWT token

  return (
    <Routes>
      <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
      
      <Route path="/" element={<Layout />}>
        <Route index element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
        {isAuthenticated ?
        <>
          <Route path="daisy" element={<DaisyMainPage />} />
          <Route path="newsletter" element={<NewsLetterPanel />} />
          <Route path="hpm" element={<HPMMainPage />} />
          <Route path="miningmachines" element={<MiningMachine flag={false}/>} />
          <Route path="allmachine" element={<MiningMachine flag={true}/>} />
          <Route path="gold" element={<HumanGold />} />
          <Route path="altcoin" element={<HPMMainPage />} />
          <Route path="leverage" element={<LeveragedTrading />} />
          <Route path="contracts" element={<ContractsPage />} />
          <Route path="group-manager" element={<GroupManager />} />
          <Route path="manager" element={<UserManager />} />
          <Route path="orgmanager" element={<OrgManager />} />
          <Route path="company" element={<CompanyDetails />} />
          <Route path="depositregister" element={<DepositRegisterPage/>} />
          <Route path="newsmanager" element={<NewsManager />} />
          <Route path="toplist" element={<TopList />} />
          <Route path="vote" element={<Vote />} />
          <Route path="/myminingmachine/:id" element={<MyMiningMachine/>} />
          <Route path="/cp/:id" element={<PurchaseCoinsPanel/>} />
          <Route path="/payoutmanager/:id" element={<PayoutRegisterPage/>} />
          <Route path="/miningmachinesdaily" element={<MiningMachineTypeManager/>} />
          <Route path="/miningbeneficiaries" element={<MiningBeneficiariesPanel/>} />
          <Route path="/minedtrading" element={<MinedCoinTraderPanel/>} />
          <Route path="payouts" element={<DaisyPayoutManager/>}/>
          <Route path="totalpayouts" element={<TotalDaisyPayoutManager/>}/>
          <Route path="votelabels" element={<VoteLabelManager/>}/>
          
          <Route path="newsletterlist" element={<NewsLetterManagerList/>}/>
          
          
          
          
          <Route path="group-connections" element={<GroupConnectionsList />} />
          
          <Route path="envelopes" element={<ContractHistory archivedListOpen={false}/>} />
        </> 
        : null }
      </Route>
      
      <Route path="*" element={<NoPage />} />
    </Routes>
  )
}