import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { OpenLeverage } from "../../../../models/leveragetrading.model";
import { Asset } from "../../../../models/assets.model";
import { Group } from "../../../../models/group.model";
import { Assets } from "../../../../enums/assets";
import { getCoinExchangeRate } from "../../../../api/getCoinExchangeRate";
import { responseHero } from "../../../../utils/axiosInterceptor";
import { CoinPrices } from "../../../../pages/admin/LeveragedTrading";

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
    coinPrices: CoinPrices;
};

type ModifiedLeverage = Omit<OpenLeverage, "id">

export const OpenLeverageTradingModal = ({ group, title, showModal, closeModal, handleStateChange, coinPrices }: ModalProps) => {

    const [recordData, setRecordData] = useState<ModifiedLeverage>({
        group_id: -1,
        base: 0,
        multiplier: 5,
        open: 0,
        assetType: Assets.BTC, 
        exchange_rate: 0, 
        pl: ""
    });

    useEffect(() => {
        if(!recordData.assetType && (
            recordData.assetType === Assets.BTC || recordData.assetType === Assets.ETH
        )) return;

        
    }, [recordData.assetType])

    const handleChange = (field: keyof ModifiedLeverage, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const inputValue = e.target.value;
  
      setRecordData(prevData => {
        const updatedRecordData = {
            ...prevData,
            [field]: field === 'multiplier' ? parseFloat(inputValue) : parseInt(inputValue, 10),
        };

        if (field === 'base' || field === 'multiplier') {
            updatedRecordData.open = updatedRecordData.base * updatedRecordData.multiplier;
        } else if (field === 'assetType') {
            updatedRecordData.assetType = parseInt(inputValue, 10);
        }

        return updatedRecordData;
      });
    };

    const makeRecord = async () => {
        if(!group) return;
        await responseHero.post(`${process.env.REACT_APP_API_URL}/leverage/${group.group_id}`, { record: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen létrehoztad a nyitást!`);
        }).catch(error => {
            closeModal()
            toast.error(`Sikertelen rögzítés!`);
        })
    }

    

    return (
        <>
            {showModal && (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-4xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                    <h3 className="text-xl text-brown font-semibold">
                                        <span className="text-dark-blue">HUMAN D.C: Tőkeáttét - {title}</span>
                                    </h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form>

                                    <div className="flex p-2 rounded shadow-md bg-gray-200 mb-5">
                                        <div className="w-full text-center items-center">
                                            <label className="block text-sm font-bold mt-5 text-brown text-center justify-center">
                                                <span className="text-success">Eszköz Típusa</span>
                                            </label>
                                            <div className="flex justify-center mt-2">
                                                <label className="mr-4 text-lg">
                                                    <input
                                                        type="radio"
                                                        name="assetType"
                                                        value={1}
                                                        checked={recordData.assetType === Assets.BTC}
                                                        onChange={() => setRecordData({ ...recordData, assetType: Assets.BTC })}
                                                        className="mr-2 w-6 h-6"
                                                    />
                                                    Bitcoin
                                                </label>
                                                <label className="mr-4 text-lg">
                                                    <input
                                                        type="radio"
                                                        name="assetType"
                                                        value={2}
                                                        checked={recordData.assetType === Assets.ETH}
                                                        onChange={() => setRecordData({ ...recordData, assetType: Assets.ETH })}
                                                        className="mr-2 w-6 h-6"
                                                    />
                                                    Ethereum
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                        <div className="flex p-2 rounded shadow-md bg-gray-200 mb-5">
                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="base">
                                                    <span className="text-success">Alaptőke</span>
                                                </label>
                                                <input
                                                    value={recordData.base}
                                                    onChange={(e) => handleChange('base', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="base"
                                                    type="number"
                                                    placeholder="Alaptőke"
                                                />
                                            </div>

                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="multiplier">
                                                    <span className="text-success">Tőkeáttét</span>
                                                </label>
                                                <select
                                                    value={recordData.multiplier}
                                                    onChange={(e) => handleChange('multiplier', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="multiplier"
                                                >
                                                    {[1,2,3,4,5].map((value) => (
                                                        <option key={value} value={value}>{`${value}X`}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="w-1/3 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="open">
                                                    <span className="text-success">Tőkeáttétes pozíció</span>
                                                </label>
                                                <input
                                                    value={recordData.open}
                                                    onChange={(e) => handleChange('open', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="open"
                                                    type="number"
                                                    placeholder="Tökeáttétes pozíció"
                                                />
                                            </div>
                                            {/* jelenlegi árfolyam */}
                                            {/* P/L */}
                                        </div>

                                        <div className="flex p-2 rounded shadow-md bg-gray-200 mb-5">
                                            <div className="w-1/2 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="base">
                                                    <span className="text-success">Jelenlegi árfolyam</span>
                                                </label>
                                                <p className="text-xl mt-2">{recordData?.assetType === Assets.BTC ? coinPrices.btc?.toLocaleString('en-En').replace(',', ' ') 
                                                : coinPrices.eth?.toLocaleString('en-En').replace(',', ' ')}$</p>
                                            </div>
                                            <div className="w-1/2 pr-2 text-center items-center">           
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="base">
                                                    <span className="text-success">Vételi árfolyam</span>
                                                </label>
                                                <input
                                                    value={recordData.exchange_rate}
                                                    onChange={(e) => handleChange('exchange_rate', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="exchange_rate"
                                                    type="number"
                                                    placeholder="Vételi árfolyam"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={closeModal}
                                    >
                                        Mégse
                                    </button>
                                    <button
                                        className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={makeRecord}
                                    >
                                        Mentés
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
                </>
            )}
        </>
    );
};
