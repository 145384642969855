import { InvestmentBoundaries } from "../types/InvestmentBoundaries";
import { responseHero } from "../utils/axiosInterceptor";

export const getDepositBoundaries = (group_id: number, assetType: number): Promise<InvestmentBoundaries[]> => {
    return new Promise<InvestmentBoundaries[]>((resolve, reject) => {
        if(!group_id || !assetType) return;
        responseHero.get(`${process.env.REACT_APP_API_URL}/purchaseintervals/${group_id}/${assetType}`).then((response) => {
            resolve(response.data as InvestmentBoundaries[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};