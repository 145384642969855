import React, { useEffect, useState } from "react";
import { responseHero } from "../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Daisy } from "../../../models/daisy.model";
import { Group } from "../../../models/group.model";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/reducers/userSlice";
import { dateFormatter } from "../../../utils/dateFormatter";
import { getLastDaisyRecord } from "../../../api/getLastDaisyRecord";

type ModifiedPayload = Pick<Daisy, "date" | "creatorId" | "deposit" | "deposit_to_crypto" | "deposit_to_forex" | "sum_share_amount" | "share_exchange_rate" | "daisy_token" |"requestable_crypto" | "requestable_forex" |
"in_trade_crypto" | "in_trade_forex" | "sum_share_amount_2" | "share_exchange_rate_2" | "share_dividend_per_amount" | "share_dividend_per_amount_2"
| "assigned_to_customer_crypto" | "assigned_to_customer_forex" | "logical_dividend_request" | "trading_capital_statement_crypto" | "trading_capital_statement_forex" |
"trade_closing_crypto" | "trade_closing_forex" | "gross_forex_trading_profit" | "gross_crypto_trading_profit">

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    closeModal: () => void;
    handleStateChange: () => void;
}

const AddNewRecordModal = ({ group, title, showModal, closeModal, handleStateChange } : Omit<ModalProps, "user">) => {

    const user = useSelector(selectUser);

    const [checked, setChecked] = useState(false)

    const [recordData, setRecordData] = useState<ModifiedPayload>({
        date: new Date(),
        creatorId: user.userInfo.user_id,
        deposit: 0,

        deposit_to_crypto: 0,
        deposit_to_forex: 0,

        in_trade_crypto: 0, // new
        in_trade_forex: 0, // new

        trading_capital_statement_crypto: 0,
        trading_capital_statement_forex: 0,

        share_exchange_rate: 0,
        sum_share_amount: 0,
        share_exchange_rate_2: 0, // new
        sum_share_amount_2: 0, // new

        share_dividend_per_amount: 0, // new
        share_dividend_per_amount_2: 0, // new
        daisy_token: 0,

        requestable_crypto: 0,
        requestable_forex: 0,
        assigned_to_customer_crypto: 0, // new
        assigned_to_customer_forex: 0, // new

        trade_closing_crypto: 0,
        trade_closing_forex: 0,
        gross_forex_trading_profit: 0,
        gross_crypto_trading_profit: 0,

        logical_dividend_request: false,

    });


    const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement>) => {
        setRecordData(prevData => ({
            ...prevData,
            [type]: type === "date" ? e.target.value : 
            type !== "logical_dividend_request" ? parseInt(e.target.value, 10) : !checked,
        }));
    };

    React.useEffect(() => {
        getLastDaisyRecord(group)
        .then((response) => {
            if(response !== null) {
                setRecordData(response)
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }, [group])


    const makeRecord = async () => {

        if(!group) return;

        await responseHero.post(`${process.env.REACT_APP_API_URL}/daisy/${group.group_id}`, { record: recordData }).then((response) => {
            closeModal()
            handleStateChange();
            toast.success(`Sikeresen létrehoztad a rekordot!`);
        }).catch(error => {
            console.error(JSON.stringify(error.response.data.message, null, 2));
            closeModal()
            toast.error(`Sikertelen adatrögzítés!`);
        })
    }

    return (
    <>
        {showModal && (
            <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                            <h3 className="text-xl font=semibold"><span className="text-brown">DAISY</span> - {title} | {recordData?.date?.toString().split('T')[0]}</h3>
                        </div>
                        <div className="relative p-6 flex-auto">
                            <form>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">Dátum </span> <br/>Rögzítés dátuma
                                        </label>
                                        <input
                                            value={recordData?.date?.toString().split('T')[0]}
                                            onChange={(e) => handleChange('date', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" placeholder="Dátum"/>
                                    
                                    </div>

                                    <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">Befizetés CRYPTO.com </span> <br/> USD
                                        </label>
                                        <input
                                            value={recordData?.deposit}
                                            onChange={(e) => handleChange('deposit', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="inpayment" type="number" placeholder="EUR, USD, HUF"/>
                                    </div>

                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">CRYPTO </span> <br/> Ki és befizetés
                                        </label>
                                            <input
                                            value={recordData?.deposit_to_crypto}
                                            onChange={(e) => handleChange('deposit_to_crypto', e)}

                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="crypto_1" type="number" placeholder=""/>
                                    
                                    </div>
                                    
                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-gray-800">FOREX </span> <br/> Ki és befizetés
                                        </label>
                                            <input
                                            value={recordData?.deposit_to_forex}
                                            onChange={(e) => handleChange('deposit_to_forex', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="forex_1" type="number" placeholder=""/>
                                    </div>
                                </div>
                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">CRYPTO </span> <br/> Kereskedésbe került összeg
                                        </label>
                                            <input
                                            value={recordData?.in_trade_crypto}
                                            onChange={(e) => handleChange('in_trade_crypto', e)}

                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="crypto_1" type="number" placeholder=""/>
                                    
                                    </div>
                                    
                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-gray-800">FOREX </span> <br/> Kereskedésbe került összeg
                                        </label>
                                            <input
                                            value={recordData?.in_trade_forex}
                                            onChange={(e) => handleChange('in_trade_forex', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="forex_1" type="number" placeholder=""/>
                                    </div>

                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">CRYPTO </span> <br/> Kereskedési tőke kimutatás
                                        </label>
                                            <input
                                            value={recordData?.trading_capital_statement_crypto}
                                            onChange={(e) => handleChange('trading_capital_statement_crypto', e)}

                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="crypto_1" type="number" placeholder=""/>
                                    
                                    </div>
                                    
                                    <div className="w-1/4 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-gray-800">FOREX </span> <br/> Kereskedési tőke kimutatás
                                        </label>
                                            <input
                                            value={recordData?.trading_capital_statement_forex}
                                            onChange={(e) => handleChange('trading_capital_statement_forex', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="forex_1" type="number" placeholder=""/>
                                    </div>
                                </div>

                                <div className="flex p-2 rounded shadow-md mt-5 bg-cream">
                                    <div className="w-1/5 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="stock_trading">
                                        <span className="text-success">I. RÉSZVÉNY </span> <br/> Árfolyam
                                        </label>
                                            <input
                                            value={recordData?.share_exchange_rate}
                                            onChange={(e) => handleChange('share_exchange_rate', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="stock_trading" type="number" placeholder=""/>
                                    </div>
                                    <div className="w-1/5 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="stock_amount">
                                        <span className="text-success">I. RÉSZVÉNY </span> <br/> Darabszám
                                        </label>
                                            <input
                                            value={recordData?.sum_share_amount}
                                            onChange={(e) => handleChange('sum_share_amount', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="stock_amount" type="number" placeholder=""/>
                                    </div>

                                    <div className="w-1/5 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="stock_trading">
                                        <span className="text-success">II. RÉSZVÉNY </span> <br/> Árfolyam
                                        </label>
                                            <input
                                            value={recordData?.share_exchange_rate_2}
                                            onChange={(e) => handleChange('share_exchange_rate_2', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="stock_trading" type="number" placeholder=""/>
                                    </div>
                                    <div className="w-1/5 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="stock_amount">
                                        <span className="text-success">II. RÉSZVÉNY </span> <br/> Darabszám
                                        </label>
                                            <input
                                            value={recordData?.sum_share_amount_2}
                                            onChange={(e) => handleChange('sum_share_amount_2', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="stock_amount" type="number" placeholder=""/>
                                    </div>
                                    <div className="w-1/5 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="stock_amount">
                                        <span className="text-success">RÉSZVÉNY OSZTALÉK </span> <br/> Kikérem {checked}
                                        </label>
                                        <div className="flex flex-row items-center">
                                            <input
                                            onChange={(e) => {
                                                handleChange('logical_dividend_request', e)
                                                setChecked(!checked)
                                            }}
                                                className="w-6 h-6 m-auto" id="stock_amount" type="checkbox" placeholder=""/>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex p-2 rounded shadow-md">
                                    <div className="w-1/3 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="stock_trading">
                                        <span className="text-success">OSZTALÉK </span> <br/>I. Részvény / db
                                        </label>
                                            <input
                                            value={recordData?.share_dividend_per_amount}
                                            onChange={(e) => handleChange('share_dividend_per_amount', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="stock_trading" type="number" placeholder=""/>
                                    </div>
                                    <div className="w-1/3 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="stock_amount">
                                        <span className="text-success">OSZTALÉK </span> <br/>II. Részvény / db
                                        </label>
                                            <input
                                            value={recordData?.share_dividend_per_amount_2}
                                            onChange={(e) => handleChange('share_dividend_per_amount_2', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="stock_amount" type="number" placeholder=""/>
                                    </div>
                                    <div className="w-1/3 pr-2">
                                        <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                        <span className="text-success">DAISY </span> <br/>Tokenek száma
                                        </label>
                                            <input
                                            value={recordData?.daisy_token}
                                            onChange={(e) => handleChange('daisy_token', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="account_balance" type="number" placeholder=""/>
                                    </div>
                                </div>
                            
                            <div className="flex p-2 rounded shadow-md">
                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                    <span className="text-success">KIKÉRHETŐ </span> <br/> CRYPTO
                                    </label>
                                        <input
                                        value={recordData?.requestable_crypto}
                                        onChange={(e) => handleChange('requestable_crypto', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>
                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-gray-800 text-center" htmlFor="crypto_1">
                                    <span className="text-success">KIKÉRHETŐ </span> <br/> FOREX
                                    </label>
                                        <input
                                        value={recordData?.requestable_forex}
                                        onChange={(e) => handleChange('requestable_forex', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>

                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                    <span className="text-success">ÜGYFÉL RÉSZÉRE KIUTALT </span> <br/> CRYPTO
                                    </label>
                                        <input
                                        value={recordData?.assigned_to_customer_crypto}
                                        onChange={(e) => handleChange('assigned_to_customer_crypto', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>
                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-gray-800 text-center" htmlFor="crypto_1">
                                    <span className="text-success">ÜGYFÉL RÉSZÉRE KIUTALT </span> <br/> FOREX
                                    </label>
                                        <input
                                        value={recordData?.assigned_to_customer_forex}
                                        onChange={(e) => handleChange('assigned_to_customer_forex', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>
                            </div>

                            <div className="flex p-2 rounded shadow-md bg-cream">
                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                    <span className="text-success">Kereskedésbe zárt tőke </span> <br/> CRYPTO
                                    </label>
                                        <input
                                        value={recordData?.trade_closing_crypto}
                                        onChange={(e) => handleChange('trade_closing_crypto', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>
                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-gray-800 text-center" htmlFor="crypto_1">
                                    <span className="text-success">Kereskedésbe zárt tőke </span> <br/> FOREX
                                    </label>
                                        <input
                                        value={recordData?.trade_closing_forex}
                                        onChange={(e) => handleChange('trade_closing_forex', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>

                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-brown text-center" htmlFor="crypto_1">
                                    <span className="text-success">Brutto kereskedési profit </span> <br/> CRYPTO
                                    </label>
                                        <input
                                        value={recordData?.gross_crypto_trading_profit}
                                        onChange={(e) => handleChange('gross_crypto_trading_profit', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>
                                <div className="w-1/4 pr-2">
                                    <label className="block text-sm font-bold mt-5 text-gray-800 text-center" htmlFor="crypto_1">
                                    <span className="text-success">Brutto kereskedési profit </span> <br/> FOREX
                                    </label>
                                        <input
                                        value={recordData?.gross_forex_trading_profit}
                                        onChange={(e) => handleChange('gross_forex_trading_profit', e)}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="output" type="number" placeholder=""/>
                                </div>
                            </div>

                            </form>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={closeModal}
                        >
                            Mégse
                        </button>
                        <button
                            className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={makeRecord}
                        >
                            Hozzáad
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
            </>
        )}
    </>
  );
};

export default AddNewRecordModal;