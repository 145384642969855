import React, { useEffect } from "react";
import { ModalPropsWithUserInvestmentData } from "../../../types/modalProps";
import { getPayoutsByGroup, getRecordPayouts } from "../../../api/getRecordPayouts";
import { Payout } from "../../../models/payout.model";
import { Daisy } from "../../../models/daisy.model";
import { Assignments } from "../../../types/assigments";
import { getDaisyRecords } from "../../../api/getDaisyRecords";
import { updateAssignmentsInPayoutModal } from "../functions/updateAssignmentsInPayoutModal";
import ModifyAssignmentsModal from "./ModifyAssignmentsModal";

const PayoutPercentagesModal = ({ title, showModal, closeModal, users, userPercentages, group } : Omit<ModalPropsWithUserInvestmentData, "user" | "handleStateChange">) => {
    
    const sum = userPercentages.reduce((accumulator, currentValue) => {
        accumulator.client += currentValue.allocated_amount.client;
        accumulator.company += currentValue.allocated_amount.company;
        return accumulator;
    }, { client: 0, company: 0 });

    const [payouts, setPayouts] = React.useState<Payout[]>([]);
    const [payoutIds, setPayoutIds] = React.useState<number[]>([]);

    const [assignments, setAssignments] = React.useState<Assignments[]>([]);
    const [records, setRecords] = React.useState<Daisy[]>([])

    const [showModifyAssignmentsModal, setShowModifyAssignmentsModal] = React.useState<boolean>(false); // edit record assignment state
    const [recodsStateChange, setRecordsStateChange] = React.useState<boolean>(false)

    const [selectedRecord, setSelectedRecord] = React.useState<Daisy>();

    const openModifyAssignmentsModal = (recordId: number) => {

        if(!recordId) return;
        
        const foundRow = records.find((record) => record.transaction_id === recordId);
        
        if(foundRow) {
            setShowModifyAssignmentsModal(true)
            closeModal();
            setSelectedRecord(foundRow);
        }
        
    };
    const closeModifyAssigmentsModal = () => {
        setShowModifyAssignmentsModal(false)
    };

    React.useEffect(() => {

        if(!group) return;

        const getGroupPayouts = async () => {
            const payouts = await getPayoutsByGroup(group?.group_id)
            setPayouts(payouts);

            const ids = payouts.map((payout) => payout.daisyId)
            setPayoutIds(ids);
        }

        getGroupPayouts();
        
    }, [group]);

    React.useEffect(() => {
        if(!group) return;

        getDaisyRecords(group)
        .then((response) => {
            setRecords(response)
        })
        .catch((error) => {
            console.error(error);
        });
    }, [group?.group_id])


    React.useEffect(() => {
        const updateAssignments = () => {
            const assignmentResults = records.filter((record) => record.assigned_to_customer_crypto > 0 || record.assigned_to_customer_forex > 0);
            updateAssignmentsInPayoutModal(assignmentResults).then((res) => {
                setAssignments(res);
            });
            
        }
        updateAssignments();
    }, [records]);


    const handleStateChange = () => {
        setRecordsStateChange(!recodsStateChange)
    }

    return (
        <>
            <ModifyAssignmentsModal assignments={assignments} title="Kifizetések kezelése" group={group} row={selectedRecord} showModal={showModifyAssignmentsModal} closeModal={closeModifyAssigmentsModal} handleStateChange={handleStateChange}/>
            {(showModal && users && users?.length > 0) && (
                <>
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                            <h3 className="text-3xl font=semibold">{title}</h3>
                            </div>
                            <div className="max-h-96 p-6 overflow-y-auto">


                                <h1 className="text-center text-lg">A kifizetésnél a következő az elosztás:</h1>

                                <div className="mt-2 mb-2 bg-gray-800 flex space-x-8 rounded-md px-2 py-2">
                                    <p className="text-white">Ügyfél része összesen: <span className="text-cream font-semibold">{sum?.client?.toLocaleString('en-En').replace(',', ' ') || "Nincs adat"}$</span></p>
                                    <p className="text-white">Cég része összesen: <span className="text-cream font-semibold">{sum?.company?.toLocaleString('en-En').replace(',', ' ') || "Nincs adat" }$</span></p>
                                </div>

                                <table className="w-full  border-collapse">
                                    <thead>
                                        <tr className="bg-gray-800 text-white border-b">
                                            <th className="py-2 px-4 text-center">Dátum</th>
                                            <th className="py-2 px-4 text-center">Név</th>
                                            <th className="py-2 px-4 text-center">Rész</th>
                                            <th className="py-2 px-4 text-center">Ügyfél részére (Forex)</th>
                                            <th className="py-2 px-4 text-center">Ügyfél részére (Crypto)</th>
                                            <th className="py-2 px-4 text-center">Ügyfél részére összesen</th>
                                            <th className="py-2 px-4 text-center">Cég részére</th>
                                            <th className="py-2 px-4 text-center">Kiutalva?</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {userPercentages.map((userPercentage, index) => (
                                            <tr key={index} className={`${index % 2 === 0  ? 'bg-gray-800' : 'bg-gray-600'} text-white`}>
                                                <td className="py-2 px-4 text-center">{userPercentage.date.toString().split('T')[0]}</td>
                                                <td className={`py-2 px-4 text-center ${userPercentage.transaction_id !== -1 ? '' : 'bg-red-400'}`}>{userPercentage?.user?.name}</td>
                                                <td className="py-2 px-4 text-center">{userPercentage.share.toFixed(2)}%</td>

                                                <td className={`py-2 px-4 text-center ${userPercentage.allocated_amount.forex > 0 ?
                                                'text-green-200 font-semibold' : 'text-gray-500'}`}>{userPercentage.allocated_amount.forex?.toLocaleString('en-En').replace(',', ' ') || "Nincs adat"}$</td>
                                                <td className={`py-2 px-4 text-center ${userPercentage.allocated_amount.crypto > 0 ?
                                                'text-cream font-semibold' : 'text-gray-500'}`}>{userPercentage.allocated_amount.crypto?.toLocaleString('en-En').replace(',', ' ') || "Nincs adat"}$</td>

                                                <td className="py-2 px-4 text-center text-green-300">{userPercentage.allocated_amount.client?.toLocaleString('en-En').replace(',', ' ') || "Nincs adat"}$</td>
                                                <td className={`py-2 px-4 text-center ${userPercentage.allocated_amount.company > 0 ?
                                                'text-cream font-semibold' : 'text-gray-500'}`}>{userPercentage.allocated_amount.company?.toLocaleString('en-En').replace(',', ' ') || "Nincs adat"}$</td>

                                                <td 
                                                onClick={() => openModifyAssignmentsModal(userPercentage?.transaction_id)}
                                                className={`hover:cursor-pointer hover:underline py-2 px-4 text-center ${payoutIds.includes(userPercentage.transaction_id) ?
                                                'text-cream font-semibold' : 'text-gray-300'}`}>
                                                    {payoutIds.includes(userPercentage.transaction_id) ? 'Kiutalás rögzítve': 'Rögzítésre vár'}
                                                </td>
                                            
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                onClick={closeModal}
                            >
                                Bezárás
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </>
    );
}

export default PayoutPercentagesModal;