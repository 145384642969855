import React, { useState } from "react";
import { toast } from "react-toastify";
import { responseHero } from "../../utils/axiosInterceptor";
import { getVoteLabelData } from "../../api/getVoteLabelData";
import { VoteLabel } from "../../models/votelabel.model";

export default function VoteLabelManager() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voteLabelText, setVoteLabelText] = useState("");
  const [voteLabels, setVoteLabels] = useState<VoteLabel[]>([]);

  React.useEffect(() => {
    getVoteLabelData()
      .then((res) => setVoteLabels(res)) // Store fetched vote labels
      .catch((error) => console.error(error));
  }, []);

  const addNewVoteLabel = async (voteLabel: Omit<VoteLabel, "id">) => {
    try {
      const res = await responseHero.post(
        `${process.env.REACT_APP_API_URL}/votelabel`,
        { voteLabel }
      );
      toast.success("Sikeres a szavazóelem mentése!");
      setVoteLabels((prev) => [...prev, { id: res.data.id, ...voteLabel }]); // Update list with the new ID
      setVoteLabelText(""); // Reset input field
      setIsModalOpen(false); // Close modal
    } catch (error) {
      toast.error("Sikertelen a szavazóelem mentése!");
    }
  };

  const removeVoteLabel = async (id: number) => {
    try {
      await responseHero.delete(`${process.env.REACT_APP_API_URL}/votelabel/${id}`);
      toast.success("Sikeres a szavazóelem törlése!");
      setVoteLabels((prev) => prev.filter((label) => label.id !== id)); // Remove label from list
    } catch (error) {
      toast.error("Sikertelen a szavazóelem törlése!");
    }
  };

  return (
    <div className="m-5 p-3">
      <p className="text-xl font-bold">Szavazóelemek elhelyezése</p>

      <button
        className="mt-4 px-4 py-2 bg-brown text-dark-blue text-white font-semibold rounded hover:bg-dark-brown"
        onClick={() => setIsModalOpen(true)}
      >
        Új szavazóelem létrehozása
      </button>

      {/* Render voteLabels list */}
      <div className="mt-4">
        {voteLabels.length === 0 ? (
          <p className="text-gray-500">Nincsenek szavazóelemek.</p>
        ) : (
          <ul className="list-disc pl-5">
            {voteLabels.map((label) => (
              <li key={label.id} className="text-gray-800 flex items-center justify-between py-2 px-2 border-2 border-gray-500 mb-2">
                <span>{label.text}</span>
                <button
                  className="ml-4 px-3 py-1 bg-error text-white font-semibold rounded hover:bg-red-600"
                  onClick={() => removeVoteLabel(label.id)}
                >
                  Törlés
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
        >
          <div className="bg-white rounded shadow-lg w-96 p-5">
            <h2 className="text-lg font-bold mb-4">Új szavazóelem</h2>

            <input
              type="text"
              value={voteLabelText}
              onChange={(e) => setVoteLabelText(e.target.value)}
              placeholder="Szavazóelem szövege"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300 mb-4"
            />

            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 bg-gray-300 text-black font-semibold rounded hover:bg-gray-400"
                onClick={() => setIsModalOpen(false)}
              >
                Mégse
              </button>

              <button
                className="px-4 py-2 bg-green-500 text-white font-semibold rounded hover:bg-green-600"
                onClick={() => addNewVoteLabel({ text: voteLabelText })}
              >
                Létrehoz
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
