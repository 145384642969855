import { News } from "../models/news.model";
import { NewsLetterGroup } from "../models/newslettergroup.model";
import { System } from "../models/system.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getNewsLetterGroupData = (): Promise<NewsLetterGroup[]> => {
    return new Promise<NewsLetterGroup[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/newslettergroup`).then((response) => {
            resolve(response.data as NewsLetterGroup[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};