import { VoteLabel } from "../models/votelabel.model";
import { responseHero } from "../utils/axiosInterceptor";

export const getVoteLabelData = (): Promise<VoteLabel[]> => {
    return new Promise<VoteLabel[]>((resolve, reject) => {
        responseHero.get(`${process.env.REACT_APP_API_URL}/votelabel`).then((response) => {
            resolve(response.data as VoteLabel[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};