import React from "react";
import { getAssets } from "../../../api/getAssets";
import { Asset } from "../../../models/assets.model";
import { useSelector, useDispatch } from "react-redux";
import { SET_SELECTED_ASSET, selectedAssetState } from "../../../store/reducers/selectedAssetSlice";
import { toast } from "react-toastify";
import InvestmentBoundariesPanel from "./tables/boundaries/InvestmentBoundariesPanel";
import { selectMenuStates } from "../../../store/reducers/menuSlice";
export default function TableBoundaries() {
  
    const [assets, setAssets] = React.useState<Asset[]>([])
    const dispatch = useDispatch();
    const selectedAsset = useSelector(selectedAssetState);
    const menu = useSelector(selectMenuStates);

    React.useEffect(() => {
      getAssets()
      .then((response) => {
        setAssets(response)
      })
      .catch((error) => {
          console.error(error);
      }); 
    }, [selectedAsset])

  const handleAssetSelection = (name: string) => {
    const findAsset = assets.find((asset) => asset.name === name);
    if(!findAsset) return;
    dispatch(SET_SELECTED_ASSET(findAsset))
    toast.info("Új fókusz: " + findAsset?.name);
  }

  return (
    <div className="p-3">
        <div className="flex flex-row justify-center items-center align-center bg-gray-300 rounded-md p-3">
        <label className="text-lg mr-2">Kérlek válaszd ki a kriptóeszközt: </label>
        <select defaultValue={selectedAsset ? selectedAsset.name : "Kérlek válassz"} onChange={(e) => handleAssetSelection(e.target.value)} className="w-52 bg-dark-blue text-white text-white px-4 py-3 rounded-lg shadow-md">
            <option className="px-2 py-1 text-center" disabled={true} value={"Kérlek válassz"}>Kérlek válassz</option>
            {assets.map((asset, index) => (
                <option value={asset?.name} className="px-2 py-1 text-center" key={index}>{asset?.name}</option>
            ))}
        </select>
        </div>
        
        {menu.daisyMenu && selectedAsset && (
            <InvestmentBoundariesPanel group_id={menu?.daisyMenu?.group_id} assetType={selectedAsset?.id}/>
        )}
        
    </div>
  );
}