import { Crypto } from "../../../../models/crypto.model";
import { Asset } from "../../../../models/assets.model";
import { TradingHelper } from "../../../../models/tradinghelper.model";
import { getPercentage } from "./helperfunctions/getPercent";
import React from "react";
import { getCryptoRecords } from "../../../../api/getHumanDCFunctions";

type AssetsMenuProps = {
  selectedAsset: Asset | undefined;
  takenRecords: Crypto[];
  tradingHelperData: TradingHelper | undefined;
  coinQuantity: string;
}

export default function AssetSellPanel({ selectedAsset, takenRecords, tradingHelperData, coinQuantity } : AssetsMenuProps) {
   
  return (
    <>
      <div className="">    
            <h1 className="text-2xl font-semibold py-2">{selectedAsset?.name} : <span className="text-error">Eladás</span></h1>
            {(takenRecords.length > 0 && tradingHelperData) ? (
                <>
                <div className="bg-white py-2 px-2 rounded shadow-sm shadow-gray-400 mt-5">
                    <h1 className="text-2xl text-center">Rendelkezésre álló coin darabszám: <span className="text-brown">{parseFloat(coinQuantity).toFixed(selectedAsset?.alt ? 2 : 8)} (db)</span></h1>
                
                    <div className="flex space-x-4 px-2 py-2 items-center justify-center">
                        <p className="text-xl">A fenti mennyiség: <span className="text-brown">{tradingHelperData.coin_lvl}%</span> -a:</p>
                        <p className="text-center text-xl text-emerald-500">
                            {getPercentage(parseFloat(coinQuantity), tradingHelperData.coin_lvl).toFixed(selectedAsset?.alt ? 2 : 8)} db
                        </p>
                    </div>

                    <div className="flex px-2 py-2 justfiy-center items-center">
                        <p className="text-xl"><span className="text-brown">{tradingHelperData.increasePercent}%-os</span> árfolyam-emelkedésnél az árfolyam: <span className="text-success font-semibold ">{(tradingHelperData.avg_transaction_rate * (1 + (tradingHelperData.increasePercent / 100))).toFixed(2)}$</span></p>
                    </div>
                </div>
                </>
            ) : (
                <div className="bg-white py-2 px-2 rounded shadow-sm shadow-gray-400 mt-5">
                    <p className="text-xl">Jelenleg még nincsen konfigurálva a következő: <span className="font-semibold">{selectedAsset?.name}</span></p>
                </div>
            )}
        </div>
    </>
  );
}